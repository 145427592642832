<template>
  <v-app style="padding-bottom: 10px">
    <v-main>
          <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  created() {
    this.$store.dispatch('checkDebug');
  },

  components: {
  },

  data: () => ({
    //
  }),
};
</script>

<style>
.v-navigation-drawer,
.v-toolbar,
.mx-auto,
.v-list.v-sheet.theme--dark.v-list--dense,
.v-sheet.theme--dark.v-toolbar {
  background: #24272c !important;
}

.v-application,
.theme--dark .v-sheet {
  background: #34373c !important;
}

.theme--dark th, .theme--dark .v-data-table {
  background-color: #1a1a21 !important;
}
</style>